export const AccountsRoutes = {
  LOGIN: '/login',
  REGISTER: '/register',
  GET_USER_DATA: '/user/me',
  GET_ATTACHMENT: '/getAttachment',
  REGISTER_MEMBER: '/registerMember',
  RESET_PASSWORD: '/account/resetPassword',
  FORGOT_PASSWORD: '/account/forgotPassword',
  GET_SHIP_AND_PAY_METHODS: '/getShipAndPayMethods',
  ACCOUNT_CHANGE_PASSWORD: '/account/changepassword'
};

export const CompanyRoutes = {
  SAVE_LOGO: '/company/saveLogo',
  GET_USERS: '/company/users/get',
  UPDATE_COMPANY: '/company/update',
  GET_ALL_COMPANIES: '/company/getAll',
  CREATE_NEW_COMPANY: '/company/create',
  GET_EMPLOYEES: '/company/getUsersList',
  CREATE_EMPLOYEE: '/company/createUser',
  ADD_THRESHOLDS: '/company/addThreshold',
  GET_THRESHOLDS: '/company/getThresholds',
  EDIT_THRESHOLD: '/company/editThreshold',
  COLLECTION_GET: '/company/collection/get',
  ADD_EMPLOYEE: '/company/addUserToCompany',
  DELETE_THRESHOLDS: '/company/deleteThreshold',
  EDIT_COMPANY_USER: '/company/editCompanyUser',
  GET_INVITES_LIST: '/company/users/invitesList',
  DELETE_COMPANY_MEMBER: '/company/users/remove',
  ADD_USER_TO_COMPANY: '/company/addUserToCompany',
  ADD_SHIPPING_ADDRESS: '/company/addShippingAddress',
  ADD_INVOICE_ADDRESS: '/company/addInvoiceAddress',
  CANCEL_INVITATION: '/company/users/cancelInvitation',
  ADD_SUPPLIER_TO_PRODUCT: '/company/collection/addProducts',
  GET_USERS_VALUATIONS_CREATE: '/company/getUsersValuationsCreate',
  REMOVE_SUPPLIER_FROM_PRODUCT: '/company/collection/removeProducts',
  SEND_WELCOME_MESSAGE: (id) => `/account/sendWelcomeEmail/${id}`,
  DELETE_SHIPPING_DETAILS: (id) => `/company/deleteShippingAddress/${id}`,
  DELETE_INVOICE_DETAILS: (id) => `/company/deleteInvoiceAddress/${id}`
};

export const UserRoutes = {
  ME: '/user/me',
  ADD_SUPPLIER: '/suppliers/add',
  ADD_PRODUCT: '/user/addProduct',
  EDIT_SUPPLIER: '/suppliers/edit',
  ADD_PRODUCER: '/user/addProducer',
  GET_USER_PRODUCTS: '/user/products',
  UPDATE_USER_DATA: '/user/updateAccount',
  GET_NOTIFICATIONS: '/user/notifications',
  GET_DISTRIBUTORS: '/user/getDistributors',
  SET_NOTIFICATION_AS_READ: '/user/readNotification',
  SAVE_DASHBOARD_CONFIG: '/user/saveDashboardConfig',
  GET_UNREAD_NOTIFICATIONS: '/user/unreadNotifications',
  GET_USER_PROD_CREATION_DATA: '/user/getProductCreationData',
  SET_ALL_NOTIFICATIONS_AS_READ: '/user/readAllNotifications',
  DELETE_SUPPLIER: (id) => `suppliers/delete/${id}`
};

export const ListsRoutes = {
  GET_LISTS: '/lists/get',
  MOVE_LIST: '/lists/move',
  CREATE_LIST: '/lists/create',
  GET_CART_DATA: '/lists/getCart',
  MERGE_LISTS: '/lists/mergeLists',
  EDIT_LIST_NAME: '/lists/editName',
  LIST_DUPLICATE: '/lists/duplicate',
  SELECT_PRICE: '/lists/selectPrice',
  CREATE_ORDER: '/lists/createOrder',
  SAVE_LIST_COMMENT: '/lists/setComment',
  GET_LIST_PRODUCTS: '/lists/getProducts',
  DELETE_PRODUCTS: '/lists/deleteProducts',
  REPLACE_PRODUCTS: 'lists/replaceProducts',
  GET_CART_SUMMARY: '/lists/getCartSummary',
  GET_LIST_ACTIONS: '/lists/getListActions',
  SELECT_PREFERRED: '/lists/selectPreferred',
  STORE_PRODUCT_TO_LIST: '/lists/storeToList',
  CREATE_PRICING: '/valuations/createPricing',
  REMOVE_ALL_SELECTS: '/lists/removeAllSelects',
  REMOVE_ALL_SELECTS_BY_LIST_UID: '/lists/removeAllSelectsByListUid',
  RESOLVE_ORDER_DATA: '/lists/resolveOrderData',
  SET_PRODUCTS_COMMENT: '/lists/setProductsComment',
  OPTIMIZE_OFFER_CATALOG: '/lists/optimizeOfferCatalog',
  SET_PREFERRED_SUPPLIERS: '/lists/setPreferredSuppliers',
  CREATE_HORIZONTAL_PRICING: '/valuations/createHorizontalPricing',
  GET_RECENTLY_ORDERED_PRODUCTS: '/lists/getRecentlyOrderedProducts',
  GET_OFFER_CATALOG_PRODUCTS_WITHOUT_PRICE: '/lists/getOfferCatalogProductsWithoutPrice',
  DELETE_LIST: (uid) => `/lists/delete/${uid}`,
  SET_PRODUCT_DEPARTMENT: '/lists/setProductDepartment',
  DELETE_PRODUCT_DEPARTMENT: '/lists/deleteProductDepartment',
  EXPORT_YOUR_CART_DATA: '/lists/exportYourCartData'
};

export const ProductsRoutes = {
  GET_PRODUCTS: '/products',
  GET_PRODUCERS: '/producers',
  GET_CATEGORIES: '/categories',
  ADD_PRODUCT: '/admin/addProduct',
  REPLACE_PRODUCT: '/products/replaceProduct',
  EDIT_PRODUCT_EAN: '/products/editProductEan',
  GET_PRODUCTS_PROPERTIES: '/getProductsProperties',
  GET_PROD_CREATION_DATA: '/getProductCreationData',
  EXPORT_ALL_PRODUCTS: '/products/exportAllProducts',
  IMPORT_NEW_PRODUCTS: '/products/importNewProducts',
  IMPORT_EDITED_PRODUCTS: '/products/importEditedProducts',
  PRODUCT_ORDERS: (id) => `/products/productOrders/${id}`,
  PRODUCT_DETAILS: (id) => `/products/productDetails/${id}`,
  PRODUCT_PRICINGS: (id) => `/products/productPricings/${id}`,
  DELETE_PRODUCT: (product_id) => `/products/delete/${product_id}`
};

export const InvoicesRoutes = {
  GET_INVOICES: '/invoices/get',
  GET_ACTIONS: '/invoices/getActions',
  ADD_USER_INVOICE: '/invoices/addUserInvoice',
  DOWNLOAD_INVOICE: '/invoices/downloadInvoice',
  EDIT_USER_INVOICE: '/invoices/editUserInvoice',
  BULK_STATUS_CHANGE: '/invoices/bulkStatusChange',
  DELETE_USER_INVOICE: '/invoices/deleteUserInvoice',
  ADD_USER_INVOICE_OTHER: '/invoices/addUserInvoiceOther',
  DOWNLOAD_INVOICE_OTHER: '/invoices/downloadInvoiceOther',
  EDIT_USER_INVOICE_OTHER: '/invoices/editUserInvoiceOther',
  DELETE_USER_INVOICE_OTHER: '/invoices/deleteUserInvoiceOther'
};

export const OCRInvoicesRoutes = {
  ANALYZE_INVOICE: '/analyzeInvoice',
  EDIT_INVOICE: '/admin/ocr-invoices/editInvoice',
  UPLOAD_INVOICE: '/admin/ocr-invoices/uploadInvoice',
  DELETE_INVOICE: '/admin/ocr-invoices/deleteInvoice',
  ADD_INVOICE_PRODUCT: '/admin/ocr-invoices/addProduct',
  ASSIGN_PRODUCTS: '/admin/ocr-invoices/assignProducts',
  DOWNLOAD_INVOICE: '/admin/ocr-invoices/downloadInvoice',
  GET_INVOICES_LIST: '/admin/ocr-invoices/getInvoicesList',
  FILL_OFFER_CATALOG: '/admin/ocr-invoices/fillOfferCatalog',
  DELETE_INVOICE_PRODUCT: '/admin/ocr-invoices/deleteProduct',
  GET_INVOICE_DETAILS: '/admin/ocr-invoices/getInvoiceDetails',
  LINK_INVOICE_PRODUCT: '/admin/ocr-invoices/linkInvoiceProduct',
  EDIT_INVOICE_PRODUCT: '/admin/ocr-invoices/editInvoiceProduct'
};

export const ExpensesRoutes = {
  ADD_TAG: '/expenses/addTag',
  GET_TAGS: '/expenses/getTags',
  EDIT_TAG: '/expenses/editTag',
  DELETE_TAG: '/expenses/deleteTag',
  ADD_CATEGORY: '/expenses/addCategory',
  EDIT_CATEGORY: '/expenses/editCategory',
  ADD_USER_EXPENSE: '/expenses/addExpense',
  GET_CATEGORIES: '/expenses/getCategories',
  GET_HISTORY: '/expenses/getExpenseHistory',
  GET_USER_EXPENSES: '/expenses/getExpenses',
  EDIT_USER_EXPENSE: '/expenses/editExpense',
  DELETE_CATEGORY: '/expenses/deleteCategory',
  DELETE_USER_EXPENSE: '/expenses/deleteExpense',
  ADD_EXPENSE_FILES: '/expenses/addExpenseFiles',
  DELETE_EXPENSE_FILE: '/expenses/deleteExpenseFile',
  GET_EXPENSES_GROUPED: '/expenses/getExpensesGrouped',
  DOWNLOAD_EXPENSE_FILE: '/expenses/downloadExpenseFile',
  ADD_USER_EXPENSE_PAYMENT: '/expenses/addExpensePayment',
  GET_USER_EXPENSE_PAYMENTS: '/expenses/getExpensePayments',
  EDIT_USER_EXPENSE_PAYMENT: '/expenses/editExpensePayment',
  DELETE_USER_EXPENSE_PAYMENT: '/expenses/deleteExpensePayment',
  ADD_EXPENSE_PAYMENT_FILES: '/expenses/addExpensePaymentFiles',
  DELETE_EXPENSE_PAYMENT_FILE: '/expenses/deleteExpensePaymentFile',
  GET_EXPENSE_PAYMENTS_GROUPED: '/expenses/getExpensePaymentsGrouped',
  DOWNLOAD_EXPENSE_PAYMENT_FILE: '/expenses/downloadExpensePaymentFile'
};

export const OfferCatalogRoutes = {
  GET_DATA: '/offerCatalog/get',
  ADD_PRICE: '/offerCatalog/addPrice',
  GET_SUMMARY: '/offerCatalog/summary',
  SELECT_PRICE: '/offerCatalog/selectPrice',
  CREATE_ORDER: '/offerCatalog/createOrder',
  DELETE_PRICE: '/offerCatalog/deletePrice',
  DELETE_REF_PRICE: '/offerCatalog/deleteRefPrice',
  GET_PRODUCTS: '/offerCatalog/getProducts',
  DELETE_PRODUCT: '/offerCatalog/deleteProduct',
  EXPORT_CATALOG: '/offerCatalog/exportCatalog',
  CHANGE_QUANTITY: '/offerCatalog/changeQuantity',
  EDIT_OFFER_PRICE: '/offerCatalog/editPriceOffer',
  SELECT_PREFERRED: '/offerCatalog/selectPreferred',
  RESOLVE_ORDER_DATA: '/offerCatalog/resolveOrderData',
  REMOVE_ALL_SELECTS: '/offerCatalog/removeAllSelects',
  EDIT_PRICE_COMMENT: '/offerCatalog/editPriceComment',
  IMPORT_CATALOG_PRICES: '/offerCatalog/importCatalogPrices',
  OPTIMIZE_OFFER_CATALOG: '/offerCatalog/optimizeOfferCatalog',
  SET_PREFERRED_SUPPLIER: '/offerCatalog/setPreferredSupplier',
  EXPORT_PRODUCTS_VOLUME: '/offerCatalog/exportProductsVolume',
  IMPORT_PRODUCTS_VOLUME: '/offerCatalog/importProductsVolume',
  GET_VOLUMES_FOR_PRODUCT: '/offerCatalog/getVolumesForProduct',
  SET_PREFERRED_SUPPLIERS: '/offerCatalog/setPreferredSuppliers',
  ADD_PRODUCT_MONTH_VOLUME: '/offerCatalog/addProductMonthVolume',
  GET_SUPPLIERS_PARTICIPATION: '/suppliers/getSuppliersParticipation',
  GET_CATALOG_PRICES_HISTORY: '/offerCatalog/getCatalogPricesHistory',
  SET_AVG_VOLUMES_FOR_CATALOG: '/offerCatalog/setAvgVolumesForCatalog',
  SET_AVG_VOLUMES_WITH_RANGE_FOR_CATALOG: '/offerCatalog/setAvgVolumesWithRangeForCatalog',
  DELETE_PRICE_COMMENT: (id) => `/offerCatalog/deletePriceComment/${id}`,
  EXPORT_CATALOG_PRICES_HISTORY: '/offerCatalog/exportCatalogPricesHistory',
  EXPORT_ALL_PRODUCTS_FOR_CATALOG: '/offerCatalog/exportAllProductsForCatalog',
  GET_CATALOG_SAVINGS_FOR_VOLUMES: '/offerCatalog/getCatalogSavingsForVolumes',
  GET_VOLUMES_HISTORY_FOR_PRODUCT: '/offerCatalog/getVolumesHistoryForProduct',
  EXPORT_CATALOG_SUPPLIERS_OFFERS: '/offerCatalog/exportCatalogSuppliersOffers',
  GET_ANALYTICS_SUMMARY_FOR_OFFER_CATALOG: '/statistics/getAnalyticsSummaryForOfferCatalog',
  GET_OFFER_CATALOG_PRODUCTS_WITHOUT_PRICE: '/offerCatalog/getOfferCatalogProductsWithoutPrice'
};

export const ValuationsRoutes = {
  GET_VALUATIONS: '/valuations/get',
  CREATE_ORDER: '/valuations/createOrder',
  SHOW_COLUMNS: '/valuations/showColumns',
  HIDE_COLUMNS: '/valuations/hideColumns',
  RESOLVE_ORDER: '/valuations/resolveOrderData',
  OPTIMIZE_SELECT: '/valuations/selectOptimized',
  GET_VALUATION_PRODUCTS: '/valuations/getproducts',
  SELECT_SUBSTITUTE: '/valuations/selectSubstitute',
  GET_PENDING_STATUS: '/valuations/getPendingStatus',
  CANCEL_VALUATION: (uid) => `/valuations/delete/${uid}`,
  SAVE_ORDER_DIST: '/valuations/saveOrderDataDistributor',
  SAVE_ORDER_PRODUCTS: '/valuations/saveOrderDataProducts',
  CHANGE_SUPPLIER_OFFER: '/valuations/changeSupplierOffer',
  SELECT_ALL_IN_COLUMN: '/valuations/selectDistributorColumn',
  CHANGE_ORDER_QUANTITY: '/valuations/changePreOrderQuantity',
  DELETE_OFFER_SELECTION: '/valuations/removeProductSelection',
  GET_VALUATION_PRODUCTS_SIMPLE: '/valuations/getSimpleProducts',
  UNSELECT_ALL_IN_COLUMN: '/valuations/unselectDistributorColumn',
  CREATE_SUPPLIER_VALUATION: '/valuations/createSupplierValuation',
  CHANGE_SELECTED_PRODUCT_DIST: '/valuations/changeSelectedProductDistributor',
  ADD_PRICES: '/valuations/addPrices'
};

export const OrdersRoutes = {
  GET_ORDERS: '/orders/get',
  ADD_MESSAGE: '/orders/addMessage',
  GET_PRODUCTS: '/orders/getProducts',
  CANCEL_ORDER: '/orders/cancelOrder',
  CANCEL_ORDER_LINK: '/orders/cancelOrderLink',
  ADD_PRODUCT_COMMENT: '/orders/addProductComment',
  SEND_ORDER_APPROVAL: '/orders/sendOrderApproval',
  SEND_SYSTEM_MESSAGE: '/orders/sendSystemMessage',
  REFRESH_ORDERS_STATUS: '/orders/refreshOrdersStatus',
  ADD_ORDER_TO_WAREHOUSE: '/orders/addOrderToWarehouse',
  CHANGE_PRODUCTS_STATUS: '/orders/changeProductsStatus',
  UPLOAD_USER_ATTACHMENT: '/orders/uploadUserAttachment',
  DELETE_USER_ATTACHMENT: '/orders/deleteUserAttachment',
  PARTIAL_RECEIVE_PRODUCTS: '/orders/partialReceiveProducts',
  SEND_SUPPLIER_SYSTEM_MESSAGE: '/supplier/orders/sendSystemMessage',
  GET_ORDER_PRODUCTS_DEPARTMENTS: '/orders/getOrderProductsDepartments',
  EXPORT_ORDER_PRODUCTS_DEPARTMENTS: '/lists/exportOrderProductsDepartments',
  GET_SHIPMENT_DETAILS: '/shipmentAPI/getShipmentDetails',
  ADD_SHIPMENT_NUMBER: '/shipmentAPI/addShipmentNumber',
  DELETE_SHIPMENT_NUMBER: '/shipmentAPI/deleteShipmentNumber'
};

export const SupplierRoutes = {
  GET_FORM: '/links/getForm',
  GET_ORDER: '/links/getOrder',
  SAVE_PRICE: '/links/savePrice',
  GET_ORDERS: '/supplier/orders',
  ADD_MESSAGE: '/links/addMessage',
  CANCEL_ORDER: '/links/cancelOrder',
  SAVE_ORDER_LINK: '/links/saveOrder',
  GET_PRODUCTS: '/supplier/prices/get',
  GET_CLIENTS: '/supplier/clients/get',
  SAVE_FORM_DATA: '/links/saveFormData',
  UPDATE_ORDER: '/links/updateOrderData',
  FINALIZE_ORDER: '/links/finalizeOrder',
  SEND_VALUATION: '/links/sendValuation',
  GET_VALUATIONS: '/supplier/valuations',
  ORDER_APPROVAL: '/links/orderApproval',
  CHANGE_PRICE: '/supplier/prices/change',
  ACCEPT_NEW_PRICE: '/links/acceptNewPrice',
  ADD_TO_COLLECTION: '/supplier/prices/add',
  GET_COLLECTIONS: '/supplier/prices/getAll',
  DOWNLOAD_INVOICE: '/links/downloadInvoice',
  ADD_SHIPPING_METHOD: '/links/addShippingMethod',
  ACCEPT_NEW_QUANTITY: '/links/acceptNewQuantity',
  ORDER_APPROVAL_DATA: '/links/orderApprovalData',
  REMOVE_FROM_COLLECTION: '/supplier/prices/remove',
  UPDATE_SHIPPING_PRICE: '/links/updateShippingPrice',
  EDIT_SUPPLIER_INVOICE: '/links/editSupplierInvoice',
  SEND_PARTIAL_PRODUCTS: '/links/sendPartialProducts',
  HIDE_SUPPLIER_OFFERS: '/supplier/hideSupplierOffers',
  DELETE_SHIPPING_METHOD: '/links/removeShippingMethod',
  UPLOAD_SUPPLIER_INVOICE: '/links/uploadSupplierInvoice',
  DELETE_SUPPLIER_INVOICE: '/links/deleteSupplierInvoice',
  EXPORT_SUBORDER_PRODUCTS: '/links/exportSuborderProducts',
  UPLOAD_SUPPLIER_ATTACHMENT: '/links/uploadSupplierAttachment',
  DELETE_SUPPLIER_ATTACHMENT: '/links/deleteSupplierAttachment',
  REMOVE_OFFER_OR_REPLACEMENT: '/links/deleteOffersReplacements',
  REGISTER_SUPPLIER_ACCOUNT: '/supplier/registerSupplierAccount',
  SEND_ORDERED_PRODUCTS_BY_SUPPLIER: '/links/sendOrderedProductsBySupplier'
};

export const DistributorRoutes = {
  GET_ORDER: '/distributor/orders'
};

export const AdminRoutes = {
  GET_USERS: '/admin/user',
  ADD_USER: '/admin/user/create',
  ADD_CATEGORY: '/admin/addCategory',
  ADD_PRODUCER: '/admin/addProducer',
  GET_CATEGORIES: '/admin/categories',
  GET_USER_ROLES: '/admin/user/roles',
  GET_USER_TOKEN: '/admin/user/token',
  GET_COMPANIES: '/admin/company/get',
  MOVE_COMPANY: '/admin/company/move',
  SAVE_CATEGORY: '/admin/saveCategory',
  HIDE_CATEGORY: '/admin/hideCategory',
  SHOW_CATEGORY: '/admin/showCategory',
  ACTIVATE_USER: '/admin/user/activate',
  DELETE_CATEGORY: '/admin/deleteCategory',
  MOVE_VALUATION: '/admin/valuations/move',
  DEACTIVATE_USER: '/admin/user/deactivate',
  SAVE_SUPPLIER: '/admin/distributors/save',
  USER_TO_DB_PRODUCT: '/admin/UserProductToDb',
  USER_PRODUCT_TO_DB: '/admin/userProductToDb',
  VALUATION_EXTEND: '/admin/valuations/extend',
  DELETE_COMPANY: '/admin/company/deleteCompany',
  INVITE_USER_TO_COMPANY: '/admin/company/invite',
  GET_COMPANIES_AS_TREE: '/admin/company/getTree',
  GET_ORDER_APPROVALS: '/admin/getOrderApprovals',
  CHANGE_ORDER_STATUS: '/admin/orders/changeOrderStatus',
  CHANGE_ORDER_PRODUCT_STATUS: '/admin/orders/changeOrderProductStatus',
  ACTIVATE_COMPANY: '/admin/company/activateCompany',
  EDIT_PRODUCT_PRICE: '/admin/lists/editProductPrice',
  MOVE_PRODUCER_PRODUCTS: '/admin/moveProducerProducts',
  MOVE_CATEGORY_PRODUCTS: '/admin/moveCategoryProducts',
  CHANGE_SUPPLIER_EMAIL: '/admin/user/changeSupplierEmail',
  ADD_SHIPPING_ADDRESS: '/admin/company/addShippingAddress',
  GET_COMPANY_MEMBERS: '/admin/company/membersAndInvitations',
  DELETE_COMPANY_MEMBER: '/admin/company/removeUserFromCompany',
  UPDATE_BILLING_PERIOD: '/admin/company/updateBillingPeriod',
  USER_VALUATION_EXTEND: '/admin/valuations/extendUserValuation',
  REMOVE_USER_INVITE_TO_COMPANY: '/admin/company/removeInvitation',
  DELETE_SUPPLIER_USER_CONNECTION: '/admin/deleteSupplierUserConnection',
  REMIND_DISTRIBUTOR_VALUATION: '/admin/valuations/remindDistributorValuation',
  EXPORT_USERS_EMAILS: '/admin/exportUsersEmails',
  DOWNLOAD_INVOICE: '/admin/ocr-invoices/downloadInvoice',
  SET_MAINTENANCE: '/admin/setMaintenance',
  USER_DATA: (id) => `/admin/user/${id}`,
  COMPANY_DETAILS: (id) => `/admin/company/${id}`,
  DELETE_PRODUCER: (id) => `/admin/deleteProducer/${id}`,
  DELETE_DISTRIBUTOR: (id) => `/admin/distributors/delete/${id}`,
  DELETE_SHIPPING_ADDRESS: (id) => `/admin/company/deleteShippingAddress/${id}`
};

export const WarehouseRoutes = {
  GET_WAREHOUSE_DATA: '/warehouse/get',
  ADD_PRODUCT: '/warehouse/save',
  // DELETE_PRODUCT: (id) => `/warehouse/delete/${id}`,
  CHANGE_PRODUCT_STATE: '/warehouse/changeQuantity',
  // GET_WAREHOUSE_HISTORY: '/warehouse/getHistory',
  GET_PRODUCTS_HISTORY: '/warehouse/getProductsHistory',
  //
  //   new warehouse
  //
  ADD_RELEASE: '/new_warehouse/addRelease',
  GET_SUMMARY: '/new_warehouse/getSummary',
  GET_PRODUCTS: '/new_warehouse/getProducts',
  GET_DEPARTMENTS: '/new_warehouse/getDepartments',
  ADD_ORDER_SUPPLY: '/new_warehouse/addOrderSupply',
  ADD_MANUAL_SUPPLY: '/new_warehouse/addManualSupply',
  GET_PRODUCT_SUPPLIES: '/new_warehouse/getProductSupplies',
  GET_PRODUCT_RELEASES: '/new_warehouse/getProductReleases',
  ADD_OR_UPDATE_PRODUCT: '/new_warehouse/addOrUpdateProduct',
  RECOUNT_PRODUCTS_DATA: 'new_warehouse/recountProductsData',
  GET_WAREHOUSE_HISTORY: '/new_warehouse/getWarehouseHistory',
  GET_PRODUCTS_CATEGORIES: '/new_warehouse/getProductsCategories',
  ADD_OR_UPDATE_DEPARTMENT: '/new_warehouse/addOrUpdateDepartment',
  EXPORT_WAREHOUSE_HISTORY: '/new_warehouse/exportWarehouseHistory',
  EXPORT_WAREHOUSE_PRODUCTS: '/new_warehouse/exportWarehouseProducts',
  IMPORT_WAREHOUSE_PRODUCTS: '/new_warehouse/importWarehouseProducts',
  ADD_OR_UPDATE_PRODUCT_COMMENT: '/new_warehouse/addOrUpdateProductComment',
  DELETE_DEPARTMENT: (id) => `/new_warehouse/deleteDepartment/${id}`,
  DELETE_PRODUCT: (warehouseId) => `/new_warehouse/deleteProduct/${warehouseId}`,
  UPDATE_SUPPLY_PRICE: '/new_warehouse/updateSupplyPrice'
};

export const StatisticsRoutes = {
  GET_STATISTICS: '/statistics/get',
  PRODUCTS_DATA: '/statistics/productsData',
  GENERATE_REPORT: '/statistics/getMonthSummary',
  EXPORT_ORDERS_DATA: '/statistics/exportOrdersData',
  GET_TOP_PRODUCTS: '/statistics/getTop20Products',
  GET_SAVINGS_MONTHLY: '/statistics/getSavingsMonthly',
  EXPORT_SAVINGS_DATA: '/statistics/exportSavingsData',
  GET_EXPENSES_MONTHLY: '/statistics/getExpensesMonthly',
  EXPORT_TOP_PRODUCTS: '/statistics/exportTop20Products',
  GET_EXPENSES_BY_CATEGORY: '/statistics/getExpensesByCategory',
  EXPORT_EXPENSES_MONTHLY: '/statistics/exportExpensesMonthly',
  GET_ORDERS_DATA_FOR_ADMIN: '/statistics/getOrdersDataForAdmin',
  GET_ACTIONS_TIMES_FOR_ORDER: '/statistics/getActionsTimesForOrder',
  EXPORT_EXPENSES_BY_CATEGORY: '/statistics/exportExpensesByCategory',
  GET_AVG_ACTIONS_TIMES_BY_ORDER: '/statistics/getAvgActionsTimesByOrder',
  EXPORT_ACTIONS_TIMES_BY_ORDER: '/statistics/exportActionsTimesByOrder',
  GET_SAVINGS_MONTHLY_BY_COMPANIES: '/statistics/getSavingsMonthlyByCompanies',
  EXPORT_AVG_ACTIONS_TIMES_BY_ORDER: '/statistics/exportAvgActionsTimesByOrder',
  GET_EXPENSES_MONTHLY_BY_COMPANIES: '/statistics/getExpensesMonthlyByCompanies',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER: '/statistics/getProductsRealisationBySupplier',
  EXPORT_PRODUCTS_REALISATION_BY_SUPPLIER: '/statistics/exportProductsRealisationBySupplier',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER_MONTHLY: '/statistics/getProductsRealisationBySupplierMonthly',
  GET_PRODUCTS_REALISATION_BY_SUPPLIER_FOR_ORDER_UID: '/statistics/getProductsRealisationBySupplierForOrderUid',
  GET_PRODUCT_ORDERS: (id) => `/statistics/getProductOrders/${id}`,
  GET_PRICES_CHANGES_STATISTICS: '/statistics/getPricesChangesStatistics',
  EXPORT_PRICES_CHANGES_STATISTICS: '/statistics/exportPricesChangesStatistics',
  GET_SALE_PER_PRODUCT_STATISTICS: '/statistics/getSalePerProductStatistics',
  EXPORT_SALE_PER_PRODUCT_STATISTICS: '/statistics/exportSalePerProductStatistics'
};

export const ServicesRoutes = {
  ADD_EVENT: '/services/addEvent',
  EDIT_EVENT: '/services/editEvent',
  GET_SERVICE: '/services/getService',
  ADD_SERVICE: '/services/addService',
  DELETE_EVENT: '/services/deleteEvent',
  ADD_SERVICER: '/services/addServicer',
  EDIT_SERVICE: '/services/editService',
  EDIT_SERVICER: '/services/editServicer',
  DELETE_SERVICE: '/services/deleteService',
  GET_EVENTS_LIST: '/services/getEventsList',
  DELETE_SERVICER: '/services/deleteServicer',
  UPLOAD_EVENT_FILE: '/services/uploadEventFile',
  UPLOAD_SERVICE_FILE: '/services/uploadServiceFile',
  GET_SERVICES_LIST: '/services/getServicesList',
  DELETE_EVENT_FILE: '/services/deleteEventFile',
  DELETE_SERVICE_FILE: '/services/deleteServiceFile',
  GET_SERVICERS_LIST: '/services/getServicersList',
  DOWNLOAD_EVENT_FILE: '/services/downloadEventFile',
  DOWNLOAD_SERVICE_FILE: '/services/downloadServiceFile',
  GET_SERVICE_HISTORY: '/services/getServiceHistory'
};

export const PermissionsRoutes = {
  GRANT_PERMISSION: '/permissions/grant',
  REVOKE_PERMISSION: '/permissions/revoke'
};

export const LandingRoutes = {
  SEND_EMAIL: 'contactform/registerToNewsletter'
};

export const WholesalersRoutes = {
  GET_PRODUCTS: '/wholesalers/getProducts',
  GET_WHOLESALERS: '/wholesalers/getWholesalers',
  ADD_OR_UPDATE_PRODUCT: '/wholesalers/addOrUpdateProduct',
  GET_WHOLESALER_DETAILS: '/wholesalers/getWholesalerDetails',
  ADD_OR_UPDATE_WHOLESALER: '/wholesalers/addOrUpdateWholesaler',
  IMPORT_WHOLESALER_PRODUCTS: '/wholesalers/importWholesalerProducts',
  SET_WHOLESALER_TO_SUPPLIER: '/wholesalers/setWholesalerForSupplier',
  GET_WHOLESALER_DISTRIBUTORS: '/wholesalers/getWholesalerDistributors',
  EXPORT_WHOLESALER_PRODUCTS: '/wholesalers/exportProductsForWholesaler',
  AUTO_SET_WHOLESALERS_TO_SUPPLIERS: '/wholesalers/autoSetWholesalerForSuppliers',
  EXPORT_WHOLESALERS_PRODUCTS_TEMPLATE_FILE: '/wholesalers/exportAllProductsForWholesalers',
  DELETE_WHOLESALER: (id) => `/wholesalers/deleteWholesaler/${id}`,
  DELETE_WHOLESALER_PRODUCT: (id) => `/wholesalers/deleteProduct/${id}`,
  DISSOCIATE_WHOLESALER_FROM_SUPPLIER: (id) => `/wholesalers/deleteWholesalerForSupplier/${id}`
};

export const TagsRoutes = {
  GET_TAGS: '/tags/getTags',
  ADD_TAG: '/tags/addTag',
  EDIT_TAG: '/tags/editTag',
  DELETE_TAG: '/tags/deleteTag',
  ADD_PRODUCT_TAG: '/tags/addProductTag',
  DELETE_PRODUCT_TAG: '/tags/deleteProductTag',
  GET_TAGS_HISTORY: '/tags/getTagsHistory'
};

export const ReplacementsRoutes = {
  GET_GROUPS: '/replacements/getGroups',
  ADD_GROUP: '/replacements/addGroup',
  EDIT_GROUP: '/replacements/editGroup',
  DELETE_GROUP: '/replacements/deleteGroup',
  DELETE_GROUP_PRODUCT: '/replacements/removeGroupProduct',
  ADD_GROUP_PRODUCTS: '/replacements/addGroupProducts',
  MOVE_GROUP_PRODUCT: '/replacements/moveGroupProduct',
  MERGE_GROUPS: '/replacements/mergeGroups',
  GET_PRODUCT_REPLACEMENTS: '/replacements/getProductReplacements',
  GET_NEW_GROUPS: '/replacements/getNewGroups'
};

export const VariationsRoutes = {
  GET_GROUPS: '/variations/getGroups',
  ADD_GROUP: '/variations/addGroup',
  EDIT_GROUP: '/variations/editGroup',
  DELETE_GROUP: '/variations/deleteGroup',
  DELETE_GROUP_PRODUCT: '/variations/removeGroupProduct',
  ADD_GROUP_PRODUCTS: '/variations/addGroupProducts',
  MOVE_GROUP_PRODUCT: '/variations/moveGroupProduct',
  MERGE_GROUPS: '/variations/mergeGroups',
  GET_PRODUCT_VARIATIONS: '/variations/getProductVariations',
  GET_GROUPS_NAMES: '/variations/getGroupsNames'
};

export const PriceListsRoutes = {
  GET_PRICE_LISTS: '/price_lists/getPriceLists',
  GET_PRICE_LISTS_FOR_WHOLESALER: '/price_lists/getPriceListsForWholesaler',
  GET_PRICES: '/price_lists/getPrices',
  ADD_LIST: '/price_lists/addList',
  EDIT_LIST: '/price_lists/editList',
  DELETE_LIST: '/price_lists/deleteList',
  ADD_OR_UPDATE_PRICE: '/price_lists/addOrUpdatePrice',
  DELETE_PRICE: '/price_lists/deletePrice',
  ASSIGN_SUPPLIER: '/price_lists/assignSupplier',
  DELETE_SUPPLIER: '/price_lists/deleteSupplier',
  ASSIGN_COMPANY: '/price_lists/assignCompany',
  DELETE_COMPANY: '/price_lists/deleteCompany',
  ASSIGN_CLIENT: '/price_lists/assignClient',
  DELETE_CLIENT: '/price_lists/deleteClient',
  IMPORT_PRICES: '/price_lists/importPrices',
  EXPORT_PRICES: '/price_lists/exportPrices',
  GET_TOP_COMPANIES: '/price_lists/getTopCompanies',
  GET_CLIENTS_FOR_WHOLESALER: '/price_lists/getClientsForWholesaler',
  GET_PRICE_HISTORY: '/price_lists/getPriceHistory',
  GET_WHOLESALERS: '/price_lists/getWholesalers'
};
