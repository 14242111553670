import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import cn from 'classnames';

import { Checkbox, Input, QtySelector } from 'components';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { productStatuses } from 'src/constants/enums';

import style from './ProductSelector.module.scss';

const ProductSelector = ({
  status,
  product,
  onSelectChange,
  disabled,
  isSelected,
  withExpireDate,
  withQuantityInput,
  isLoading,
  onChange,
  parentValidator
}) => {
  const { thumbnail, image, name, qty, unit_name, product_id, received_quantity, warehouse_quantity, currentQuantity } = product;

  const maxQuantityToReceive = Math.max(qty - received_quantity, 0);
  const maxQuantityToAddToWarehouse = Math.max(received_quantity - warehouse_quantity, 0);

  const [expireDate, setExpireDate] = useState();
  const [addToWarehouseQty, setAddToWarehouseQty] = useState(maxQuantityToAddToWarehouse || 0);
  const [isQtyChangeActive, setIsQtyChangeActive] = useState(false);

  const onSelectChangeHandler = (isSelected) => {
    if (withQuantityInput && maxQuantityToAddToWarehouse === 0) return;
    onSelectChange({ ...product, expireDate, addToWarehouseQty, isSelected });
  };

  useEffect(() => {
    if (withExpireDate || withQuantityInput) {
      onSelectChange({
        ...product,
        expireDate,
        addToWarehouseQty,
        isSelected: Boolean(expireDate) && Boolean(addToWarehouseQty) && addToWarehouseQty <= maxQuantityToAddToWarehouse
      });
    }
  }, [expireDate, addToWarehouseQty]);

  return (
    <div
      className={cn(style.container, {
        [style.collectPart]: status === productStatuses.completed,
        [style.withExpireDate]: withExpireDate
      })}
    >
      <div className={cn(style.wrapper, style.first)}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
        />
        <p className={style.text}>{name}</p>
      </div>

      {status !== productStatuses.completed && (
        <div className={style.wrapper}>
          {withQuantityInput ? (
            <ChangeQuantity
              quantity={addToWarehouseQty}
              setQuantity={setAddToWarehouseQty}
              wasQtyChange={addToWarehouseQty !== maxQuantityToAddToWarehouse}
              unit={unit_name}
              isActive={isQtyChangeActive}
              setIsActive={setIsQtyChangeActive}
              min={1}
              max={maxQuantityToAddToWarehouse}
            />
          ) : (
            <p>
              {qty} {unit_name}
            </p>
          )}
        </div>
      )}

      {withExpireDate && (
        <div className={style.wrapper}>
          <Input
            type='date'
            id='expire_date'
            name='expier_date'
            label='Data ważności produktu'
            value={expireDate || ''}
            onChange={(e) => setExpireDate(e.target.value)}
          />
        </div>
      )}
      <div className={style.wrapper}>
        <ProductStatusExtended
          product={product}
          className={style.tag}
        />
      </div>
      <div className={classNames(style.wrapper, { [style.checkboxWrapper]: status !== productStatuses.completed })}>
        {status === productStatuses.completed ? (
          <>
            <QtySelector
              wrapperStyle={style.qtySelector}
              setState={(currentQuantity) => onChange({ ...product, currentQuantity })}
              suffix={unit_name}
              state={currentQuantity}
              max={maxQuantityToReceive}
              width={120}
              rule={`not_negative|max:${maxQuantityToReceive},num`}
              validator={parentValidator}
              disabled={isLoading}
            />
            <p>
              / {maxQuantityToReceive}&nbsp;{unit_name}
            </p>
          </>
        ) : (
          <Checkbox
            value={isSelected}
            onChange={(e) => onSelectChangeHandler(e.target.checked)}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
