import _ from 'lodash';

import { Toasts } from 'components';

export const fileUploadHeaders = {
  'Content-Type': 'multipart/form-data'
};

export const apiErrorHandler = (error) => {
  if (!error.response?.data || error.message === 'canceled') {
    return [];
  }

  try {
    if (import.meta.env.VITE_NODE_ENV !== 'production') {
      return [`Server: ${error.response.data.message}, (status: ${error.response.status})`];
    } else {
      return [error.response.data.message];
    }
  } catch (e) {
    console.error('apiErrorHandler', e);
    return ['Wystąpił nieoczekiwany bląd'];
  }
};

export const isNetworkError = (err) => !err.response;
export const isUnauthorizedError = (err) => err?.status === 401;

const NETWORK_ERROR_NOTIFY_DURATION = 10000;

const notifyNetworkError = () => {
  Toasts.notifyDanger(['Coś poszło nie tak... Skontaktuj się z nami.'], { duration: NETWORK_ERROR_NOTIFY_DURATION });
};

export const throttledNotifyNetworkError = _.throttle(notifyNetworkError, NETWORK_ERROR_NOTIFY_DURATION, {
  trailing: false
});

export const compareAppVersions = (localAppVersion, incomingAppVersion) => {
  if (localAppVersion.length === 0 || incomingAppVersion.length === 0) return;

  const formatVersion = (version) => {
    return version
      .slice(1)
      .split('.')
      .map((item) => Number(item));
  };

  const local = formatVersion(localAppVersion);
  const incoming = formatVersion(incomingAppVersion);

  return incoming.some((element, index) => element > local[index]);
};

export const getUniquesFromArrays = (array1, array2) => {
  return array1
    .filter((obj1) => !array2.some((obj2) => obj2.id === obj1.id))
    .concat(array2.filter((obj2) => !array1.some((obj1) => obj1.id === obj2.id)));
};

export const getMonthName = (dateString) => {
  const [year, month] = dateString.split('-');
  const date = new Date(year, month - 1);
  const formatter = new Intl.DateTimeFormat('pl', { month: 'short' });
  const monthName = formatter.format(date);
  return monthName.charAt(0).toUpperCase() + monthName.slice(1);
};
